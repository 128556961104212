;(function(window) {
  document.body.addEventListener('click', handleClick)

  function handleClick(e) {
    var listenerElem = e.target.closest('[data-action]')
    if (listenerElem) {
      if (listenerElem.dataset.action.indexOf('click->') != -1) {
        var actiondef = listenerElem.dataset.action.substring(7)
        if (actiondef.indexOf('navig') != -1) {
          e.stopPropagation()
          listenerElem.dispatchEvent(new Event('navig', { bubbles: true }))
        }
        if (actiondef.indexOf('togglegroup') != -1) {
          e.stopPropagation()
          listenerElem.dispatchEvent(new Event('toggle', { bubbles: true }))
        }
      }
    }
  }

  function handleNavigation() {
    document.body.addEventListener('navig', handleNavig)
    function handleNavig(e) {
      const targetElem = document.querySelector('[data-target="navig"]')
      const targetNavig = e.target.dataset.action.match(/navig.(\w+)/)[1]
      updateElem(targetElem, targetNavig)
    }
    function updateElem(targetElem, targetNavig) {
      targetElem.classList.add('focus--out')
      setTimeout(function fadeOut(){
        targetElem.className = targetElem.className.replace(/(focus--\w+)/g, 'focus--' + targetNavig)
        targetElem.classList.remove('focus--out')
      },150)
    }
  }

  function toggleGroup() {
    document.body.addEventListener('toggle', handleToggle)
    function closeSiblings(elem) {
      elem.parentElement.childNodes.forEach(function closeElem(achild){
        if(achild.dataset && achild.dataset.controller == 'togglegroup') {
          achild.classList.remove('is--open')
        }
      })
    }
    function handleToggle(e) {
      var target = e.target.closest('[data-controller="togglegroup"]')
      var isopen = target.classList.contains('is--open')
      closeSiblings(target)
      if(!isopen) {
        target.classList.add('is--open')
      }
    }
  }

  handleNavigation()
  toggleGroup()
})(window)
